import React, {useState, useEffect} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function Contato(props) {
    const [mainColor, setMainColor] = useState(props.mainColor);
    const [secondaryColor, setSecondaryColor] = useState(props.secondaryColor);

    useEffect(() => {
        setMainColor(props.mainColor);
        setSecondaryColor(props.secondaryColor);
    }, [props.mainColor, props.secondaryColor])

    return (
        <div>
            <div style={{backgroundColor: mainColor}}>
                <div className="d-flex justify-content-center align-items-center animated delay-0s fadeInDown" style={{backgroundColor: "white", minHeight: "250px", zIndex: "1"}}>
                    <div className="w-100 text-center animated fadeIn delay-1s">
                        <h1 className="bungee-font">Fale com a Gente</h1>
                    </div>
                </div>

                <div className="container padding-fix card-group text-center">
                    <div className="card index-card" style={{backgroundColor: mainColor, color: "white"}}>
                        <div className={`card-body d-flex flex-column`}>
                            <div>
                                <FontAwesomeIcon icon={["fa", "envelope"]} color="white" size="2x"/>
                                <h3 className="card-title bungee-font">E-mail</h3>
                                <div className="espacamento-interno-card"></div>                
                            </div>
                            <div className="mt-auto">
                                <a className="stretched-link" href="mailto:katdevsbr@gmail.com" target="_blank" rel="noopener noreferrer" style={{color: "white"}}>katdevsbr@gmail.com</a>
                            </div>
                        </div>
                    </div>

                    <div style={{borderLeft: `thin solid white`}}></div>
                    <div style={{borderBottom: `thin solid white`}}></div>

                    <div className="card index-card" style={{backgroundColor: mainColor, color: "white"}}>
                        <div className={`card-body d-flex flex-column`}>
                            <div>
                                <FontAwesomeIcon icon={["fab", "twitter"]} color="white" size="2x"/>
                                <h3 className="card-title bungee-font">Twitter</h3>
                                <div className="espacamento-interno-card"></div>                
                            </div>
                            <div className="mt-auto">
                                <a className="stretched-link" href="https://twitter.com/katdevs" target="_blank" rel="noopener noreferrer" style={{color: "white"}}>@KatDevs</a>
                            </div>
                        </div>
                    </div>

                    <div style={{borderLeft: `thin solid white`}}></div>
                    <div style={{borderBottom: `thin solid white`}}></div>

                    <div className="card index-card" style={{backgroundColor: mainColor, color: "white"}}>
                        <div className={`card-body d-flex flex-column`}>
                            <div>
                                <FontAwesomeIcon icon={["fab", "linkedin"]} color="white" size="2x"/>
                                <h3 className="card-title bungee-font">LinkedIn</h3>
                                <div className="espacamento-interno-card"></div>                
                            </div>
                            <div className="mt-auto">
                                <a className="stretched-link" href="https://www.linkedin.com/company/katdevs" target="_blank" rel="noopener noreferrer" style={{color: "white"}}>Kat Devs</a>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div style={{color: secondaryColor, height: "400px"}}>
                <div className="h-100 ratio ratio-16x9 overflow-hidden">
                    <iframe title="Kat Devs endereço" className="embed-responsive-item" src="https://www.google.com/maps/embed/v1/place?key=AIzaSyAotcyUOOvuAAfMoKg1ynxkZn-wMaXH_fQ&q=Rua+Tamoyo+155+Meudon,Teresópolis+RJ+BR"></iframe>
                </div>
            </div>

        </div>

    );
}

export default Contato;