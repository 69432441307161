import React from 'react';

import Layout from "../components/layout.js";
import SEO from "../components/SEO.js";
import Contato from "../components/contato.js";

function Contatos () {
    return (
        <Layout>
            <SEO title="Contatos" description={`Entre em contato com a Kat Devs.`} />
            <Contato />
        </Layout>
    );
}

export default Contatos;